import axios from "axios"

const initializeApp = () => {
    
    // Setting base URL for all API request via axios
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
    // axios.defaults.baseURL = 'https://bridget-dev.tedepasa.com/api/v1/dash'
    console.log(process.env.REACT_APP_BASE_URL,'BASE URL')
    console.log(process.env.NODE_ENV,'NODE ENV')

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        console.log('dev')


    } else {
        // Prod build code
        console.log('prod')


        // Removing console.log from prod
        console.log = () => {};


        // init analytics here
    }

}

export default initializeApp